export function openFileSystem(multiple: boolean): HTMLInputElement {
  // search for existing uploader input
  const uploader = document.querySelector('#uploading-input');

  // this ensures there is at most one input type=file - uploader - inside body element
  uploader && document.body.removeChild(uploader);

  const input = document.createElement('input');
  input.setAttribute('type', 'file');
  input.setAttribute('id', 'uploading-input');
  multiple && input.setAttribute('multiple', '');

  document.body.appendChild(input);

  return input;
}
