import { APP_BASE_HREF } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { LogLevel } from '@azure/msal-common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { enableMapSet } from 'immer';

import {
  ApiConfiguration,
  AuthInterceptor,
  AuthInterceptorConfig,
  CelumAuthModule,
  SERVICE_TOKEN_INTERCEPTOR_CONFIG,
  ServiceTokenInterceptorConfig,
  tenantHrefFactory,
  TenantService
} from '@celum/authentication';
import { CelumSnackbarModule } from '@celum/common-components';
import { CelumPropertiesProvider } from '@celum/core';
import { ExperienceProperties } from '@celum/experience/domain';
import { provideReuseStrategy } from '@celum/experience/shared';
import { CelumSnackbarListModule, SnackbarList } from '@celum/internal-components';
import { LibrariesProperties } from '@celum/libraries/domain';
import { CelumEntityModule, CustomTranslateLoader, paginationTranslatorFactory, providePaginationTranslator, TranslationHelper } from '@celum/ng2base';
import { EXP_TRANSLATE_SERVICE } from '@celum/portals/api';
import { provideApplicationInsights } from '@celum/shared/domain';
import { SystembarComponent } from '@celum/shared/ui';
import { AppErrorHandler } from '@celum/shared/util';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing.module';
import { B2CTokenInterceptor } from './b2c-token.interceptor';

enableMapSet();

export function translationFactory(http: HttpClient): CustomTranslateLoader {
  return new CustomTranslateLoader(http);
}

// Important: We join all our translations in one file, so this should be the only place, where we use the Translate Module!
const translate = TranslateModule.forRoot({
  loader: {
    provide: TranslateLoader,
    useFactory: translationFactory,
    deps: [HttpClient]
  }
});

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot([]),
    HttpClientModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot(),
    MatDialogModule,

    CelumAuthModule.forRoot({
      logLevel: LogLevel.Error
    }),
    CelumEntityModule,
    CelumSnackbarListModule,
    CelumSnackbarModule,
    translate,
    SystembarComponent
  ],
  providers: [
    TranslationHelper,
    {
      provide: EXP_TRANSLATE_SERVICE,
      useClass: TranslateService
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline' }
    },
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: { disableTooltipInteractivity: true, showDelay: 500 }
    },
    {
      provide: ErrorHandler,
      useClass: AppErrorHandler
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: B2CTokenInterceptor,
      multi: true
    },
    {
      provide: SERVICE_TOKEN_INTERCEPTOR_CONFIG,
      useFactory: serviceTokenInterceptorConfigFactory,
      deps: [TenantService]
    },
    {
      provide: APP_BASE_HREF,
      deps: [TenantService],
      useFactory: tenantHrefFactory
    },
    provideApplicationInsights(),
    providePaginationTranslator(paginationTranslatorFactory('paginationInformation', { hasBottomKey: 'elementsFollow' })),
    provideReuseStrategy()
  ],
  bootstrap: [AppComponent, SnackbarList]
})
export class AppModule {}

function serviceTokenInterceptorConfigFactory(tenantService: TenantService): ServiceTokenInterceptorConfig<AuthInterceptorConfig> {
  const getConfiguration = () => {
    const tenantId = tenantService.getCurrentTenantId();

    const sporServiceTokenRequest = {
      clientId: 'spor',
      orgId: tenantId
    };

    const apiConfigurations: ApiConfiguration[] = [
      {
        apiUrls: [
          ExperienceProperties.properties.apiUrl,
          CelumPropertiesProvider.properties.appProperties.portals.apiUrl,
          CelumPropertiesProvider.properties.appProperties.portals.azureContainerUrl,
          CelumPropertiesProvider.properties.appProperties.portals.binariesUrl
        ],
        serviceTokenRequestDto: sporServiceTokenRequest,
        tenantIdHeader: tenantId
      },
      {
        apiUrls: [LibrariesProperties.properties.apiUrl],
        serviceTokenRequestDto: {
          clientId: 'slib',
          orgId: tenantId
        },
        tenantIdHeader: tenantId
      }
    ];
    return {
      apiConfigurations
    };
  };

  return {
    getInterceptorConfiguration: () => getConfiguration()
  };
}
