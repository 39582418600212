import { Injectable } from '@angular/core';
import { combineLatest, distinctUntilChanged, map, Observable, switchMap, takeUntil } from 'rxjs';

import { AccountUserRole, UserService } from '@celum/authentication';
import { isTruthy, ReactiveService } from '@celum/core';

import { ExperienceRoleService } from './experience-role.service';
import { RoleScope, RoleType } from './roles-resource.service';

@Injectable({ providedIn: 'root' })
export class ExperienceAuthorizationService extends ReactiveService {
  private rolesOfCurrentUser$ = this.userService.currentUser$.pipe(
    isTruthy(),
    switchMap(currentUser => this.experienceRoleService.getRolesForUser$(currentUser?.oid)),
    isTruthy()
  );

  constructor(
    private userService: UserService,
    private experienceRoleService: ExperienceRoleService
  ) {
    super();

    userService.userInfo$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(({ user, tenant }) => this.experienceRoleService.loadRoles({ userIds: [user.oid], scope: RoleScope.ORGANIZATION, scopeId: tenant.accountId }));
  }

  /**
   * Checks if the current user has the necessary roles to show the administration menu.
   * @returns An Observable<boolean> indicating whether the administration menu can be shown.
   */
  public canShowAdministrationMenu$(): Observable<boolean> {
    return combineLatest([this.rolesOfCurrentUser$, this.userService.accountAccessForCurrentTenant$]).pipe(
      map(_ => this.canShowAdministrationMenu()),
      distinctUntilChanged()
    );
  }

  /**
   * Checks if the current user has the necessary roles to show the administration menu synchronously.
   * @returns A boolean indicating whether the administration menu can be shown.
   */
  public canShowAdministrationMenu(): boolean {
    if (this.canShowDesignThemeMenu()) {
      return true;
    }

    return this.hasManagerRole();
  }

  /**
   * Checks if the current user has the necessary roles to show the design theme menu synchronously.
   * @returns A boolean indicating whether the design theme menu can be shown.
   */
  public canShowDesignThemeMenu(): boolean {
    const accAccess = this.userService.getAccountAccessForCurrentTenant();
    const roles = this.experienceRoleService.getRolesForUser(this.userService.getCurrentUser().oid) ?? [];

    return roles.some(role => role.scope === RoleScope.ORGANIZATION && role.scopeId === accAccess.accountId && role.type === RoleType.THEME_ADMIN);
  }

  /**
   * Checks if the current user has the necessary roles to show the design theme menu.
   * @returns An Observable<boolean> indicating whether the design theme menu can be shown.
   */
  public canShowDesignThemeMenu$(): Observable<boolean> {
    return combineLatest([this.rolesOfCurrentUser$, this.userService.accountAccessForCurrentTenant$]).pipe(
      map(_ => this.canShowDesignThemeMenu()),
      distinctUntilChanged()
    );
  }

  /**
   * Checks if the current user has the necessary roles to show the experience permissions menu.
   * @returns a boolean indicating whether the experience permissions menu can be shown.
   */
  public canShowExperiencePermissionsMenu(): boolean {
    return this.hasManagerRole();
  }

  /**
   * Checks if the current user has the necessary roles to show the experience permissions menu.
   * @returns An Observable<boolean> indicating whether the experience permissions menu can be shown.
   */
  public canShowExperiencePermissionsMenu$(): Observable<boolean> {
    return this.hasManagerRole$();
  }

  /**
   * Checks if the current user has the necessary roles to show the domain settings menu.
   * @returns a boolean indicating whether the domain settings menu can be shown.
   */
  public canShowDomainSettingsMenu(): boolean {
    return this.hasManagerRole();
  }

  /**
   * Checks if the current user has the necessary roles to show the domain settings menu.
   * @returns An Observable<boolean> indicating whether the experience domain settings menu can be shown.
   */
  public canShowDomainSettingsMenu$(): Observable<boolean> {
    return this.hasManagerRole$();
  }

  /**
   * Checks if the current user has the manager role.
   * @returns a boolean indicating whether the user is a manager.
   */
  private hasManagerRole(): boolean {
    const accAccess = this.userService.getAccountAccessForCurrentTenant();
    return accAccess?.role === AccountUserRole.MANAGER;
  }

  /**
   * Checks if the current user has the manager role.
   * @returns An Observable<boolean> indicating whether the user is a manager.
   */
  private hasManagerRole$(): Observable<boolean> {
    return this.userService.accountAccessForCurrentTenant$.pipe(
      map(_ => this.hasManagerRole()),
      distinctUntilChanged()
    );
  }
}
