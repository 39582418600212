export const LOCAL_STORAGE_LAST_LOCALES_KEY = 'SPOR-last-saved-portal-locales';

const LOCAL_STORAGE_PORTAL_FILTERS_KEY = 'SPOR-portal-filters';
const SESSION_STORAGE_PORTAL_INSIGHTS_DATE_FILTER_KEY = 'SPOR-portal-insights-date-filter';
const SESSION_STORAGE_PORTAL_INSIGHTS_LANGUAGE_FILTER_KEY = 'SPOR-portal-insights-language-filter';
const SESSION_STORAGE_PORTAL_INSIGHTS_LOCATION_FILTER_KEY = 'SPOR-portal-insights-location-filter';
export const localeStoragePortalFiltersKey = (userId: string): string => `${LOCAL_STORAGE_PORTAL_FILTERS_KEY}_${userId}`;
export const sessionStoragePortalInsightsDateFilterKey = (userId: string): string => `${SESSION_STORAGE_PORTAL_INSIGHTS_DATE_FILTER_KEY}_${userId}`;
export const sessionStoragePortalInsightsLanguageFilterKey = (userId: string): string => `${SESSION_STORAGE_PORTAL_INSIGHTS_LANGUAGE_FILTER_KEY}_${userId}`;
export const sessionStoragePortalInsightsLocationFilterKey = (userId: string): string => `${SESSION_STORAGE_PORTAL_INSIGHTS_LOCATION_FILTER_KEY}_${userId}`;
