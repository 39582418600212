import { Provider } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, BaseRouteReuseStrategy, Router, RouteReuseStrategy } from '@angular/router';

export const RELOAD_COMPONENT_FRAGMENT = 'RELOAD_COMPONENT';

function shouldReloadComponent(routeSnapshot: ActivatedRouteSnapshot): boolean {
  return routeSnapshot.fragment === RELOAD_COMPONENT_FRAGMENT;
}

/**
 * Reloads the current route, forcing the component to reinitialize if {@link ExperienceRouteReuseStrategy} was configured.
 *
 * @param router - the Angular router instance
 * @param route - the currently activated route
 */
export function reloadCurrentRoute(router: Router, route: ActivatedRoute): void {
  router.navigate([], {
    relativeTo: route,
    skipLocationChange: true, // necessary as otherwise the {@link RELOAD_COMPONENT_FRAGMENT} fragment would appear in the url
    onSameUrlNavigation: 'reload',
    fragment: RELOAD_COMPONENT_FRAGMENT
  });
}

/**
 * Custom {@link RouteReuseStrategy} to newly instantiating the component although the route didn't change.
 */
export class ExperienceRouteReuseStrategy extends BaseRouteReuseStrategy {
  public shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    if (shouldReloadComponent(future)) {
      return false;
    }
    return super.shouldReuseRoute(future, curr);
  }
}

/**
 * Use this provider to add the {@link ExperienceRouteReuseStrategy}, be able to reload the current route and newly instantiate the component.
 */
export function provideReuseStrategy(): Provider {
  return { provide: RouteReuseStrategy, useClass: ExperienceRouteReuseStrategy };
}
