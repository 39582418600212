import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, switchMap } from 'rxjs';

import { AuthService } from '@celum/authentication';
import { ExperienceProperties } from '@celum/experience/domain';

@Injectable()
export class B2CTokenInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const b2cRoutes = [`roles`].map(route => `${ExperienceProperties.properties.apiUrl}/${route}`);

    if (!b2cRoutes.some(route => req.url.includes(route))) {
      return next.handle(req);
    }

    return this.authService.getAuthResult().pipe(
      map(result => result?.idToken ?? ''),
      switchMap(token => next.handle(req.clone({ setHeaders: { 'X-B2C-token': `${token}` } })))
    );
  }
}
