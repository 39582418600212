import { FormGroup } from '@angular/forms';

import { RecursivePartial } from '@celum/core';

/**
 * Helper function to return a recursive partial of all dirty values of a form
 *
 * Example:
 * Form value: { a: 1, b: { c: 2, d: 3 } }
 * Dirty values: a, d
 * Result: { a: 1, b: { d: 3 } }
 *
 */
export function getDirtyValues<T extends FormGroup, V extends T['value']>(form: T): RecursivePartial<V> {
  const dirtyValues: RecursivePartial<V> = {};
  Object.entries(form.controls).forEach(([key, control]) => {
    if (control instanceof FormGroup) {
      dirtyValues[key as keyof V] = getDirtyValues(control);
    } else if (control.dirty) {
      dirtyValues[key as keyof V] = control.value;
    }
  });
  return dirtyValues;
}
