import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { catchError, map, mergeMap, Observable, throwError } from 'rxjs';

import { TenantService } from '@celum/authentication';
import { tapAllResponses } from '@celum/core';
import { ErrorService } from '@celum/shared/util';

import { Role, RoleScope, RolesResourceService, RoleType } from './roles-resource.service';

export type ExperienceRoleState = {
  roles?: {
    [userId: string]: Role[];
  };
};

export type AddRoleParameters =
  | {
      userId: string;
      type: RoleType;
      scope: RoleScope.ORGANIZATION;
      scopeId?: string;
    }
  | {
      userId: string;
      type: RoleType;
      scope: RoleScope;
      scopeId: string;
    };

export type LoadRolesParameters =
  | { userIds: string[]; scope: RoleScope.ORGANIZATION; scopeId?: string }
  | { userIds: string[]; scope: RoleScope; scopeId: string };

@Injectable({ providedIn: 'root' })
export class ExperienceRoleService extends ComponentStore<ExperienceRoleState> {
  public addRole = this.effect<AddRoleParameters>(parameters$ => {
    return parameters$.pipe(
      mergeMap(parameters => {
        const scopeId = parameters.scopeId ?? (parameters.scope === RoleScope.ORGANIZATION ? this.tenantService.getCurrentTenantId() : null);
        return this.rolesResourceService
          .addRole(parameters.userId, {
            ...parameters,
            scopeId
          })
          .pipe(map(() => ({ ...parameters, scopeId })));
      }),
      tapAllResponses<AddRoleParameters, HttpErrorResponse>(
        parameters => this.loadRoles({ userIds: [parameters.userId], scope: parameters.scope, scopeId: parameters.scopeId }),
        error => this.errorService.httpError(error, this.EXPERIENCE_ROLE_SERVICE)
      )
    );
  });

  public removeRole = this.effect<string>(roleId$ => {
    return roleId$.pipe(
      mergeMap(roleId => {
        const roleToRemove = this.findRoleById(roleId);
        return this.rolesResourceService.removeRole(roleId).pipe(
          map(() => roleToRemove),
          catchError(error => {
            this.loadRoles({ userIds: [roleToRemove.userId], scope: roleToRemove.scope, scopeId: roleToRemove.scopeId });
            return throwError(() => error);
          })
        );
      }),
      tapAllResponses<Role, HttpErrorResponse>(
        role => this.loadRoles({ userIds: [role.userId], scope: role.scope, scopeId: role.scopeId }),
        error => this.errorService.httpError(error, this.EXPERIENCE_ROLE_SERVICE)
      )
    );
  });

  public loadRoles = this.effect<LoadRolesParameters>(parameters$ =>
    parameters$.pipe(
      mergeMap(parameters => {
        const scopeId = parameters.scopeId ?? (parameters.scope === RoleScope.ORGANIZATION ? this.tenantService.getCurrentTenantId() : null);
        return this.rolesResourceService.getRoles(parameters.userIds, parameters.scope, scopeId).pipe(
          map(roles => ({
            userRoles: roles,
            parameters
          }))
        );
      }),
      tapAllResponses<
        {
          userRoles: Record<string, Role[]>;
          parameters: LoadRolesParameters;
        },
        HttpErrorResponse
      >(
        ({ userRoles, parameters }) => {
          this.updateRolesInState(userRoles, parameters);
        },
        error => this.errorService.httpError(error, this.EXPERIENCE_ROLE_SERVICE)
      )
    )
  );

  private readonly EXPERIENCE_ROLE_SERVICE = 'ExperienceRoleService';

  constructor(
    private rolesResourceService: RolesResourceService,
    private errorService: ErrorService,
    private tenantService: TenantService
  ) {
    super({
      roles: {}
    });
  }

  public getRolesForUser(userId: string): Role[] {
    return this.get().roles?.[userId];
  }

  public getRolesForUser$(userId: string): Observable<Role[]> {
    return this.select(state => state.roles?.[userId]);
  }

  private findRoleById(roleId: string): Role {
    return Object.values(this.get().roles)
      .flat()
      .find(role => role.id === roleId);
  }

  private updateRolesInState(roles: Record<string, Role[]>, { scope, scopeId }: LoadRolesParameters): void {
    this.patchState(state => {
      Object.entries(roles).forEach(([userId, userRoles]) => {
        state.roles[userId] = (state.roles[userId] || []).filter(role => !(role.scope === scope && role.scopeId === scopeId));
        state.roles[userId] = [...state.roles[userId], ...userRoles];
      });
      return { roles: state.roles };
    });
  }
}
