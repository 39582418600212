import { ValidatorFn } from '@angular/forms';

export type RegexValidatorOptions = {
  /** if negate is set to true, the validator will return no error if the regex does not match and vice versa. Default: false */
  negate?: boolean;
  /** define a name for the validator which will be used in the error object. Default: 'regex' */
  name?: string;
};

/** Validator function to test the control value with the given regular expression */
export function regexValidator(regex: RegExp, options?: RegexValidatorOptions): ValidatorFn {
  return control => {
    if (!control.value || !regex) {
      return null;
    }

    const error = {
      [options?.name || 'regex']: {
        valid: false,
        messageKey: 'VALIDATION.REGEX_NOT_MATCHED'
      }
    };

    const match = regex?.test(control.value);
    const valid = options?.negate ? !match : match;

    return valid ? null : error;
  };
}
