import { inject, Injector, NgModule, runInInjectionContext } from '@angular/core';
import { ActivatedRouteSnapshot, PreloadAllModules, RouterModule, RouterStateSnapshot, Routes } from '@angular/router';

import { authGuardWithLicenceCheck, LicenseType } from '@celum/authentication';
import { CelumPropertiesProvider } from '@celum/core';
import { PortalsProperties } from '@celum/portals/api';

const routes: Routes = [
  { path: 'logout', loadComponent: () => import('@celum/authentication').then(mod => mod.LogoutComponent) },
  {
    path: '',
    canActivate: [
      (state: RouterStateSnapshot) =>
        authGuardWithLicenceCheck(state, LicenseType.EXPERIENCE, CelumPropertiesProvider.properties.authentication.applicationFallbackPagesPerLanguage)
    ],
    children: [
      {
        path: 'portal/:portalSlug',
        loadComponent: () => import('@celum/experience/features/portal-overview').then(mod => mod.PortalOverviewComponent),
        children: [
          {
            path: 'designer',
            redirectTo: 'designer/'
          },
          {
            path: 'designer/:pageSlug',
            loadComponent: () => import('@celum/experience/features/portal-designer').then(mod => mod.DesignerComponent)
          },
          {
            path: 'insights',
            loadComponent: () => import('@celum/experience/features/insights-dashboard').then(mod => mod.InsightsDashboardComponent)
          },
          { path: '**', redirectTo: 'designer' }
        ]
      },
      {
        path: 'content-template/:id',
        canActivate: [() => PortalsProperties.properties.contentTemplateFeatureEnabled ?? false],
        children: [
          {
            path: 'designer',
            loadComponent: () => import('@celum/experience/features/content-template-designer').then(mod => mod.ContentTemplateDesignerComponent),
            canDeactivate: [
              async (component: any, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState: RouterStateSnapshot) => {
                const injector = inject(Injector);

                return import('@celum/experience/features/content-template-designer').then(module =>
                  runInInjectionContext(injector, () => module.hasUnsavedChangesGuard(component, currentRoute, currentState, nextState))
                );
              }
            ]
          },
          { path: '**', redirectTo: 'designer' }
        ]
      },
      {
        path: 'dashboard',
        loadComponent: () => import('@celum/experience/features/dashboard').then(mod => mod.DashboardComponent),
        children: [
          {
            path: 'content-templates',
            canMatch: [() => PortalsProperties.properties.contentTemplateFeatureEnabled ?? false],
            loadComponent: () => import('@celum/experience/features/dashboard').then(mod => mod.ContentTemplatesComponent)
          },
          { path: 'portals', loadComponent: () => import('@celum/experience/features/dashboard').then(mod => mod.PortalsComponent) },
          { path: '**', redirectTo: 'portals' }
        ]
      },
      { path: '**', redirectTo: 'dashboard' }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
