import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { from, map, Observable } from 'rxjs';

import { ExperienceAuthorizationService } from '@celum/experience/shared';
import { SimpleContextMenuItem } from '@celum/internal-components';
import { SystembarConfiguration } from '@celum/shared/ui';

@Injectable({ providedIn: 'root' })
export class ExperienceSystembarService {
  constructor(
    private authorizationService: ExperienceAuthorizationService,
    private dialog: MatDialog
  ) {}

  public getConfiguration(): Observable<SystembarConfiguration> {
    return this.authorizationService
      .canShowAdministrationMenu$()
      .pipe(map(canShowAdministrationMenu => ({ itemProvider: defaultItems => this.createMenuItems(canShowAdministrationMenu, defaultItems) })));
  }

  private createMenuItems(canShowAdministrationMenu: boolean, defaultItems: SimpleContextMenuItem[]): SimpleContextMenuItem[] {
    return canShowAdministrationMenu ? [...defaultItems, this.createAdministrationMenuItem()] : defaultItems;
  }

  private openAdministrationDialog(): Observable<void> {
    return from(import('@celum/experience/features/administration').then(mod => mod.AdministrationDialogComponent)).pipe(
      map(administrationDialogComponent =>
        this.dialog.open(administrationDialogComponent, {
          width: '100vw',
          height: '100vh',
          maxWidth: '100vw',
          autoFocus: false,
          data: {}
        })
      ),
      map(() => void 0)
    );
  }

  private createAdministrationMenuItem(): SimpleContextMenuItem {
    return {
      text: 'EXPERIENCE.MANAGEMENT_DIALOG.TITLE',
      onClick: () => this.openAdministrationDialog().subscribe(),
      dataComponentId: 'experience-administration'
    };
  }
}
