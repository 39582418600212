import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { CelumDirectivesModule, CelumPipesModule, TranslationModule } from '@celum/ng2base';

import { Sorter } from './sorter';
import { CelumIconModule } from '../icon';

@NgModule({
  imports: [
    CommonModule, MatSelectModule, MatTooltipModule, TranslateModule,
    TranslationModule, CelumDirectivesModule, CelumPipesModule,
    CelumIconModule
  ],
            declarations: [Sorter],
            exports: [Sorter]
          })
export class CelumSorterModule {
}
