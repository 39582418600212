import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import Pusher, { ChannelAuthorizationCallback } from 'pusher-js';
import { ChannelAuthorizationData, ChannelAuthorizationRequestParams } from 'pusher-js/types/src/core/auth/options';
import { Observable } from 'rxjs';

import { DataUtil } from '@celum/core';

export interface PusherConfiguration {
  appKey: string;
  authUrl: string;
  userId?: string;
  userChannelPrefix?: string;
  channelPrefix?: string;
  enableLogging?: boolean;
}

@Injectable({ providedIn: 'root' })
export class PusherService {
  private pusher: Pusher;
  private config: PusherConfiguration;

  constructor(private httpClient: HttpClient) {}

  public initialize(configuration: PusherConfiguration): void {
    this.config = configuration;

    if (configuration.enableLogging) {
      Pusher.log = console.log;
    }

    this.pusher = this.createPusher();
  }

  public watchUserChannel<P>(eventName: string): Observable<P> {
    const channel = `${this.config.userChannelPrefix ?? ''}${this.config.userId}`;
    return this.bindToChannel(channel, eventName);
  }

  public watchChannel<P>(destination: string, eventName: string): Observable<P> {
    const channel = `${this.config.channelPrefix ?? ''}${destination}`;
    return this.bindToChannel(channel, eventName);
  }

  private createPusher(): Pusher {
    return new Pusher(this.config.appKey, {
      cluster: 'eu',
      channelAuthorization: {
        customHandler: this.authorizationHandler.bind(this),
        endpoint: undefined,
        transport: 'ajax'
      }
    });
  }

  private authorizationHandler(params: ChannelAuthorizationRequestParams, callback: ChannelAuthorizationCallback): void {
    this.httpClient.post<ChannelAuthorizationData>(this.config.authUrl, params).subscribe({
      next: result => callback(null, result),
      error: err => callback(err, null)
    });
  }

  private bindToChannel<T>(channelName: string, eventName: string): Observable<T> {
    if (!this.pusher) {
      console.error(`PusherService: channel can only be watched after calling "initialize"!`);
      return null;
    }

    console.debug(`PusherService: subscribe to channel ${channelName}`);
    const channel = this.pusher.subscribe(channelName);
    return new Observable<T>(subscriber => {
      const callback = (payload: T) => subscriber.next(payload);
      console.debug(`PusherService: bind to event ${eventName}`);
      channel.bind(eventName, callback);

      return () => {
        console.debug(`PusherService: unbind event ${eventName}`);
        channel.unbind(eventName, callback);
        if (DataUtil.isEmpty(channel.callbacks._callbacks)) {
          console.debug(`PusherService: unsubscribe channel ${eventName}`);
          channel.unsubscribe(); // do not receive further messages if there are no bindings for any event on that channel
        }
      };
    });
  }
}
