import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { take } from 'rxjs';

import { AuthService, TenantService, UserService } from '@celum/authentication';
import { isTruthy } from '@celum/core';
import { ExperienceProperties } from '@celum/experience/domain';
import {
  ContentTemplatesImportService,
  localeStoragePortalFiltersKey,
  sessionStoragePortalInsightsDateFilterKey,
  sessionStoragePortalInsightsLanguageFilterKey,
  sessionStoragePortalInsightsLocationFilterKey
} from '@celum/experience/shared';
import { RouteParamService } from '@celum/ng2base';
import { TRACKING_SERVICE, TrackingEvents, TrackingProperty, TrackingService } from '@celum/shared/domain';
import { LanguageInitializationService, PusherService, removeCelumLoadingOverlay, UserPilot, UserPilotInfo, WIZARD_URL_PARAM } from '@celum/shared/util';

import { ExperienceSystembarService } from './experience-systembar.service';

@Component({
  selector: 'exp-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent {
  protected readonly systemBarConfig$ = this.experienceSystemBarService.getConfiguration();

  constructor(
    private pusher: PusherService,
    private languageInitializationService: LanguageInitializationService,
    @Inject(TRACKING_SERVICE) private trackingService: TrackingService,
    private userPilot: UserPilot<UserPilotInfo>,
    private routeParamService: RouteParamService,
    private authService: AuthService,
    private userService: UserService,
    private tenantService: TenantService,
    private experienceSystemBarService: ExperienceSystembarService,
    private contentTemplatesImportService: ContentTemplatesImportService
  ) {
    this.routeParamService.clearParams([WIZARD_URL_PARAM]);
    this.initializeTracking();
    this.languageInitializationService.init();
    this.initializePusher();
    this.userPilot.initializeIfConfigured();
    this.cleanLocalStorageOnSignOut();

    // it's a bit strange to have this here... on the other hand, let's refactor this once we have more things to listen to...
    this.contentTemplatesImportService.listenToContentTemplateImports();
  }

  protected removeLoadingOverlay(): void {
    removeCelumLoadingOverlay();
  }

  private cleanLocalStorageOnSignOut(): void {
    this.authService.onSignOut = () => {
      const userId = this.userService.getCurrentUser().oid;
      localStorage.removeItem(localeStoragePortalFiltersKey(userId));
      // TODO update this with https://celum.atlassian.net/browse/SPOR-798
      sessionStorage.removeItem(sessionStoragePortalInsightsDateFilterKey(userId));
      sessionStorage.removeItem(sessionStoragePortalInsightsLanguageFilterKey(userId));
      sessionStorage.removeItem(sessionStoragePortalInsightsLocationFilterKey(userId));
    };
  }

  private initializePusher(): void {
    this.pusher.initialize({
      ...ExperienceProperties.properties.pusher,
      userId: 'userOid',
      userChannelPrefix: 'private-server-to-user-',
      channelPrefix: 'private-'
    });
  }

  private initializeTracking(): void {
    this.trackingService.init();
    this.trackingService.addGlobalCustomProperty(TrackingProperty.SERVICE, 'experience');

    this.userService.currentUser$.pipe(isTruthy(), take(1)).subscribe(user => {
      this.trackingService.addGlobalCustomProperty(TrackingProperty.TENANT_ID, this.tenantService.getCurrentTenantId());
      this.trackingService.addGlobalCustomProperty(TrackingProperty.USER_OID, user.oid);
      this.trackingService.trackEvent(TrackingEvents.PAGE_LOAD);
    });
  }
}
