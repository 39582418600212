import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

export const DASHBOARD_ROUTE = '/dashboard';
export const DESIGNER_ROUTE = 'designer';
export const INSIGHTS_ROUTE = 'insights';
export const PORTAL_ROUTE = '/portal';
export const CONTENT_TEMPLATES_ROUTE = 'content-templates';
export const CONTENT_TEMPLATE_ROUTE = '/content-template';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  public constructor(private router: Router) {}

  /**
   * Opens the dashboard.
   */
  public openDashboard(): void {
    this.router.navigate([DASHBOARD_ROUTE]);
  }

  /**
   * Opens Content Template dashboard
   */
  public openContentTemplatesDashboard(): void {
    this.router.navigate([DASHBOARD_ROUTE, CONTENT_TEMPLATES_ROUTE]);
  }

  /**
   * Opens the designer.
   *
   * @param portalId id of the {@link Portal} for which the Designer should be opened
   */
  public openDesigner(portalId: string): void {
    this.router.navigate([PORTAL_ROUTE, portalId, DESIGNER_ROUTE]);
  }

  /**
   * Opens the content template designer.
   *
   * @param contentTemplateId id of the {@link ContentTemplate} for which the Designer should be opened
   */
  public openContentTemplateDesigner(contentTemplateId: string): void {
    this.router.navigate([CONTENT_TEMPLATE_ROUTE, contentTemplateId, DESIGNER_ROUTE]);
  }

  /**
   * Opens the insights page for the Portal.
   *
   * @param portalId id of the {@link Portal} for which the Insights page should be opened
   */
  public openInsights(portalId: string): void {
    this.router.navigate([PORTAL_ROUTE, portalId, INSIGHTS_ROUTE]);
  }
}
