import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, map, Observable } from 'rxjs';

import { ExperienceProperties } from '@celum/experience/domain';

export enum RoleType {
  THEME_ADMIN = 'THEME_ADMIN'
}

export enum RoleScope {
  PORTAL = 'PORTAL',
  ORGANIZATION = 'ORGANIZATION'
}

export interface Role {
  id?: string;
  userId: string;
  type: RoleType;
  scope: RoleScope;
  scopeId: string;
}

export interface RoleDto {
  id?: string;
  userId: string;
  role: RoleType;
  scope: RoleScope;
  scopeId: string;
}

export interface UserRoleDto {
  userId: string;
  roles: RoleDto[];
}

@Injectable({ providedIn: 'root' })
export class RolesResourceService {
  constructor(private http: HttpClient) {}

  /**
   * Returns the permissions for the given users
   * @param userIds The user ids to get the roles for
   * @param scope The scope for which the roles are requested
   * @param scopeId The id of the scope
   * @returns An object containing the user ids as keys and the array of permissions as values
   */
  public getRoles(userIds: string[], scope: RoleScope, scopeId: string): Observable<{ [userId: string]: Role[] }> {
    if (userIds.length === 0 || scopeId === null) {
      return EMPTY;
    }

    let params = new HttpParams().set('scope', scope).set('scopeId', scopeId);
    userIds.forEach(userId => (params = params.append('userIds', userId)));

    return this.http
      .get<UserRoleDto[]>(`${ExperienceProperties.properties.apiUrl}/roles`, { params })
      .pipe(map(userRoleDtos => Object.fromEntries(userIds.map(userId => [userId, this.getRolesByUser(userId, userRoleDtos)]))));
  }

  /**
   * Adds the given permission to the given user
   * @param userId The user to add the permission for
   * @param role The permission to add
   */
  public addRole(userId: string, role: Role): Observable<void> {
    const roleDto: RoleDto = {
      userId,
      role: role.type,
      scope: role.scope,
      scopeId: role.scopeId
    };

    return this.http.post<void>(`${ExperienceProperties.properties.apiUrl}/roles`, { ...roleDto, userId });
  }

  /**
   * Removes the given role to the given user
   * @param id The id od the role to be removed
   */
  public removeRole(id: string): Observable<void> {
    return this.http.delete<void>(`${ExperienceProperties.properties.apiUrl}/roles/${id}`);
  }

  private getRolesByUser(userId: string, userRoleDtos: UserRoleDto[]): Role[] {
    const roleDto = userRoleDtos.find(dto => dto.userId === userId);
    return roleDto ? roleDto.roles.map(role => ({ ...role, type: role.role })) : [];
  }
}
