import { EnvironmentInjector, inject, InjectionToken, Provider } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

/**
 * Injection token that provides the TranslateService from experience.
 * Can be used to inject in portals if needed instead of the portals own instance.
 * @example
 * random-portal.component.ts
 * inject(EXP_TRANSLATE_SERVICE) // will inject the TranslateService from experience
 * inject(TranslateService) // will inject the TranslateService from portals
 */
export const EXP_TRANSLATE_SERVICE = new InjectionToken<TranslateService>('ExperienceTranslateService');

/**
 * Provider that provides the TranslateService from experience.
 * Can be used to inject in portal components if the component should work based on the experience locales.
 * If EXP_TRANSLATE_SERVICE is not provided (published portal), it falls back to the root TranslateService.
 */
export const useExperienceTranslateService: Provider = {
  provide: TranslateService,
  // use environment injector for the fallback as using inject(TranslateService) would result in a dependency cycle
  useFactory: (injector: EnvironmentInjector) => inject(EXP_TRANSLATE_SERVICE, { optional: true }) || injector.get(TranslateService),
  deps: [EnvironmentInjector]
};
